<template>
  <Layout>
    <b-tabs>
      <b-tab title="English">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <form-wizard
                  @on-complete="submitData"
                  color="#556ee6"
                  enctype="multipart/form-data"
                  ref="video"
                  :finishButtonText="
                    this.$route.name == 'add-video'
                      ? 'Save Data'
                      : 'Update Data'
                  "
                  back-button-text="Go Back!"
                  next-button-text="Go Next!"
                >
                  <tab-content
                    title="Video Details"
                    icon="mdi mdi-account-details"
                    :before-change="() => validateFormOne()"
                  >
                    <div class="row">
                      <b-form-group class="col-6">
                        <label for="video_title"
                          >Video Title <span style="color: red">*</span></label
                        >
                        <b-form-input
                          id="video_title"
                          v-model="form.title"
                          @keyup.prevent="slugify"
                          placeholder="Enter Video Title"
                          :class="{
                            'is-invalid': submitted && $v.form.title.$invalid,
                          }"
                        >
                        </b-form-input>
                        <div
                          v-if="submitted && !$v.form.title.required"
                          class="invalid-feedback"
                        >
                          Video Title is required.
                        </div>
                      </b-form-group>
                      <b-form-group
                        label="Thumbnail URL"
                        label-for="video_thumbnail"
                        class="col-6"
                      >
                        <b-form-input
                          id="video_thumbnail"
                          v-model="form.thumbnail_url"
                          placeholder="Enter Thumbnail URL"
                        >
                        </b-form-input>
                      </b-form-group>
                    </div>
                    <b-form-group
                      label="Tell Us More About The Video"
                      label-for="video_desc"
                    >
                      <ckeditor v-model="form.description" :editor="editor">
                      </ckeditor>
                    </b-form-group>
                    <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      id="radio-group-2"
                      v-model="form.is_vimeo"
                      @input="changeEventType"
                      :aria-describedby="ariaDescribedby"
                      name="radio-sub-component"
                    >
                      <b-form-radio value="0">Is Videocrypt Video</b-form-radio>
                      <b-form-radio value="1">Is Vimeo Video</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                    <div class="row">
                      <b-form-group class="col-6" v-if="form.is_vimeo == 1">
                        <label for="video_link"
                          >Vimeo Video ID
                          <span style="color: red">*</span></label
                        >
                        <b-form-input
                          id="video_link"
                          v-model="form.video_link"
                          placeholder="Enter Vimeo Video ID [Eg: 726000412]"
                          :class="{
                            'is-invalid':
                              submitted && $v.form.video_link.$invalid,
                          }"
                        >
                        </b-form-input>
                        <div
                          v-if="submitted && !$v.form.video_link.required && form.is_vimeo == 1"
                          class="invalid-feedback"
                        >
                          Vimeo Video ID is required.
                        </div>
                      </b-form-group>
                      <b-form-group class="col-6" v-if="form.is_vimeo == 0">
                        <label for="video_ID"
                          >VideoCrypt Video ID
                          <span style="color: red">*</span></label
                        >
                        <b-form-input
                          id="video_ID"
                          v-model="form.videocrypt_id"
                          placeholder="Must be a Valid VideoCrypt ID. [Eg:131699_0_8634139956157727]"
                          :class="{
                            'is-invalid':
                              submitted && $v.form.videocrypt_id.$invalid,
                          }"
                        >
                        </b-form-input>
                        <div
                          v-if="submitted && !$v.form.videocrypt_id.required"
                          class="invalid-feedback"
                        >
                          VideoCrypt Video ID is required.
                        </div>
                      </b-form-group>
                      <b-form-group
                        label="Thumbnail Image [Upload Max File Size : 2MB]"
                        label-for="image_name"
                        class="col-6"
                      >
                        <b-form-file
                          id="image_name"
                          accept="image/*"
                          placeholder="Choose a file or drop it here..."
                          @change="readFile($event, 'image_name')"
                          ref="image_name"
                        >
                        </b-form-file>
                        <template
                          v-if="
                            $route.name == 'edit-video' && edit.image_name_url
                          "
                        >
                          <img
                            :src="edit.image_name_url"
                            width="128px"
                            height="128px"
                            style="object-fit: contain; margin-top: 5px"
                          />
                        </template>
                      </b-form-group>
                    </div>
                  </tab-content>
                  <tab-content
                    title="Add Tags"
                    icon="fa fa-tag"
                    :before-change="() => validateFormTwo()"
                  >
                    <div class="row">
                      <b-form-group class="col-6">
                        <div
                          class="d-flex justify-content-between align-items-center mb-2"
                        >
                          <label for="comm" class="mb-0"
                            >Community <span style="color: red">*</span></label
                          >
                          <b-form-checkbox
                            v-model="status"
                            button
                            button-variant="info"
                            size="sm"
                          >
                            <template v-if="status">Unselect All</template>
                            <template v-else>Select All</template>
                          </b-form-checkbox>
                        </div>
                        <multiselect
                          @search-change="fetchCommunity"
                          id="video_community"
                          v-model="form.community_selected"
                          :options="communities"
                          :multiple="true"
                          track-by="id"
                          label="title"
                          placeholder="Type here to search"
                          :class="{
                            'is-invalid':
                              store && $v.form.community_selected.$invalid,
                          }"
                        >
                          <span slot="noOptions"> Type here to search </span>
                        </multiselect>
                        <div
                          v-if="store && !$v.form.community_selected.required"
                          class="invalid-feedback"
                        >
                          Community is required.
                        </div>
                      </b-form-group>
                      <b-form-group
                        label="Country"
                        label-for="input-multi"
                        class="col-6"
                      >
                        <multiselect
                          @search-change="fetchCountry"
                          id="input-multi"
                          v-model="form.country"
                          :options="allCountry"
                          :multiple="true"
                          track-by="name"
                          label="name"
                          style="margin-top: 15px"
                        >
                        </multiselect>
                      </b-form-group>
                      <b-form-group
                        label="Sub Speciality"
                        label-for="video_sub_spec"
                        class="col-6"
                      >
                        <multiselect
                          id="video_sub_spec"
                          v-model="form.sub_specialities"
                          :options="subspecialities"
                          :multiple="true"
                          track-by="id"
                          label="name"
                          placeholder="Select Sub Speciality"
                        >
                        </multiselect>
                      </b-form-group>
                      <b-form-group
                        label="Expert"
                        label-for="video_expert"
                        class="col-6"
                      >
                        <multiselect
                          @search-change="fetchExpert"
                          id="video_expert"
                          v-model="form.experts"
                          :options="expertsopt"
                          :multiple="true"
                          track-by="id"
                          label="name"
                          placeholder="Type here to search"
                        >
                          <span slot="noOptions"> Type here to search </span>
                        </multiselect>
                      </b-form-group>
                    </div>
                    <div class="row">
                      <b-form-group
                        label="Enter Free tags"
                        label-for="tags-separators"
                        class="col-6"
                      >
                        <b-form-tags
                          input-id="tags-separators"
                          v-model="form.tags"
                          tag-variant="primary"
                          tag-pills
                          separator=" "
                          placeholder="Enter new tags separated by space and enter"
                        >
                        </b-form-tags>
                      </b-form-group>
                      <b-form-group class="col-6">
                        <label for="video_partner"
                          >Partner <span style="color: red">*</span></label
                        >
                        <multiselect
                          @search-change="fetchPartner"
                          id="video_partner"
                          v-model="form.partner_id"
                          :options="partners"
                          :multiple="false"
                          track-by="id"
                          label="title"
                          :class="{
                            'is-invalid': store && $v.form.partner_id.$invalid,
                          }"
                          placeholder="Type here to search"
                        >
                          <span slot="noOptions"> Type here to search </span>
                        </multiselect>
                        <div
                          v-if="store && !$v.form.partner_id.required"
                          class="invalid-feedback"
                        >
                          Partner is required.
                        </div>
                      </b-form-group>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="thumbnail-card grid-case">
                          <div class="">
                            <b-form-group v-slot="{ ariaDescribedby }">
                              <label class="my-2"
                                >Select Doctor posted in forum</label
                              >
                              <b-form-radio-group
                                id="radio-group-1"
                                v-model="form.card_image_show"
                                :options="doctorPostType"
                                :aria-describedby="ariaDescribedby"
                                name="radio-options"
                              ></b-form-radio-group>
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                      <b-form-group
                        label="Member"
                        label-for="member_tagging"
                        class="col-6"
                        v-if="
                          form.card_image_show === 1 ||
                          form.card_image_show === 2 ||
                          form.card_image_show === 4
                        "
                      >
                        <multiselect
                          v-model="form.member_tagging"
                          :options="memberList"
                          :multiple="false"
                          id="member_tagging"
                          track-by="member_id"
                          label="fnameAndLname"
                          placeholder="Type here to search"
                          @search-change="fetchMemberList"
                          :class="{
                            'is-invalid':
                              store && $v.form.member_tagging.$invalid,
                          }"
                        >
                          <span slot="noOptions"> Type here to search </span>
                        </multiselect>
                        <div
                          v-if="store && !$v.form.member_tagging.required"
                          class="invalid-feedback"
                        >
                          Member is required when Doctor posted in forum is
                          selected.
                        </div>
                      </b-form-group>
                      <b-form-group
                        class="col-6"
                        label="Forum"
                        label-for="video_partner_div"
                      >
                        <multiselect
                          @search-change="fetchForum"
                          id="video_partner_div"
                          v-model="form.partner_division_id"
                          :options="forums"
                          :multiple="false"
                          track-by="id"
                          label="title"
                          placeholder="Type here to search"
                          :class="{
                            'is-invalid':
                              store && $v.form.partner_division_id.$invalid,
                          }"
                        >
                          <span slot="noOptions"> Type here to search </span>
                        </multiselect>
                        <div
                          v-if="store && !$v.form.partner_division_id.required"
                          class="invalid-feedback"
                        >
                          Forum is required when doctor posted in forum is
                          selected.
                        </div>
                      </b-form-group>
                      <div class="col-12" v-if="form.card_image_show">
                        <h6>Preview</h6>
                        <div
                          class="dc_doctor_main_grid my-4"
                          v-if="form.card_image_show"
                        >
                          <div
                            class="dc_doctors_grid"
                            :style="{
                              gridGap:
                                form.card_image_show === 4 ||
                                form.card_image_show === 3
                                  ? '0px'
                                  : '5px',
                            }"
                          >
                            <div class="">
                              <img
                                src="@/assets/images/only_doctor.png"
                                alt=""
                                v-if="form.card_image_show === 4"
                                height="50px"
                                width="50px"
                              />
                              <img
                                src="@/assets/images/only_community.png"
                                alt=""
                                v-if="form.card_image_show === 3"
                                height="50px"
                                width="50px"
                              />
                              <div
                                v-if="
                                  form.card_image_show === 1 ||
                                  form.card_image_show === 2
                                "
                                class="position-relative"
                              >
                                <img
                                  src="@/assets/images/only_community.png"
                                  alt=""
                                  height="40px"
                                  width="40px"
                                  :style="{
                                    zIndex:
                                      form.card_image_show === 1 ? '20' : '10',
                                  }"
                                  class="dc_comm_abs"
                                />
                                <img
                                  src="@/assets/images/only_doctor.png"
                                  alt=""
                                  height="40px"
                                  width="40px"
                                  class="dc_doctor_abs"
                                  :style="{
                                    zIndex:
                                      form.card_image_show === 1 ? '10' : '20',
                                  }"
                                />
                              </div>
                            </div>
                            <div
                              class="dc_main_grid"
                              v-if="form.card_image_show !== 3"
                            >
                              <div
                                class="dc_member_name"
                                v-if="form.member_tagging"
                              >
                                {{
                                  form.member_tagging.fnameAndLname.replace(
                                    /[-0-9]/g,
                                    ""
                                  )
                                }}
                              </div>
                              <div class="dc_member_name" v-else>
                                [Member name]
                              </div>
                              <div
                                class="dc_spec_city"
                                v-if="form.partner_division_id"
                              >
                                posted in
                                <span class="dc_forum">{{
                                  form.partner_division_id.title
                                }}</span>
                              </div>
                              <div class="dc_spec_city" v-else>
                                posted in
                                <span class="dc_forum">[forum_name]</span>
                              </div>
                            </div>
                            <div v-else style="align-self: center">
                              <div>{{ form.partner_division_id.title }}</div>
                            </div>
                          </div>
                          <div
                            class="dc_video_title px-4"
                            v-html="form.title"
                          ></div>
                          <div
                            class="dc_desc px-4"
                            v-html="form.description"
                          ></div>
                          <div>
                            <img
                              src="@/assets/images/thumbnail.jpg"
                              class="dc_thumbnail"
                            />
                          </div>
                          <div
                            class="dc_useful_interaction px-4 pb-3"
                            style="grid-template-columns: 1fr 1fr 1fr"
                          >
                            <div style="justify-self: start">
                              <img
                                src="@/assets/images/usefullInteraction/usefull.png"
                              />
                              <span class="ml-2">Useful</span>
                            </div>
                            <div>
                              <img
                                src="@/assets/images/usefullInteraction/save.png"
                              />
                              <span class="ml-2">Save</span>
                            </div>
                            <div style="justify-self: end">
                              <img
                                src="@/assets/images/usefullInteraction/interaction.png"
                              />
                              <span class="ml-2">Share</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <b-form-group
                        class="col-6"
                        label="Knowledge Category"
                        label-for="video_knowledge"
                      >
                        <multiselect
                          id="video_knowledge"
                          v-model="form.knowledge_categories"
                          :options="knowledgeCategories"
                          :multiple="true"
                          track-by="id"
                          label="display_name"
                          placeholder="Select Knowledge Category"
                        >
                        </multiselect>
                      </b-form-group>
                      <b-form-group
                        class="col-6"
                        label="Live Event"
                        label-for="live_event"
                      >
                        <multiselect
                          id="live_event"
                          v-model="form.live_event_id"
                          :options="getLiveEvents"
                          :multiple="false"
                          track-by="id"
                          label="title"
                          placeholder="Select Live Event"
                          @search-change="fetchLiveEvent"
                        >
                        </multiselect>
                      </b-form-group>
                      <b-form-group
                        class="col-6"
                        label="Custom Page Redirect Button Name"
                        label-for="cu"
                      >
                        <b-form-input
                          id="cu"
                          v-model="form.custom_button_name"
                          placeholder="Enter Button Name"
                        >
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="col-6"
                        label="Custom Page Redirect URL"
                        label-for="re"
                      >
                        <b-form-input
                          id="re"
                          v-model="form.custom_link"
                          placeholder="Enter URL"
                        >
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="View Multiplication Factor"
                        label-for="view_multi_factor"
                        class="col-4"
                      >
                        <b-form-input
                          id="view_multi_factor"
                          type="number"
                          placeholder="Enter View Multiplication Factor"
                          v-model="form.view_multiplication_factor"
                        >
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="col-4"
                        label="Meta Title"
                        label-for="video_meta_title"
                      >
                        <b-form-input
                          id="video_meta_title"
                          v-model="form.meta_title"
                          placeholder="Enter Meta Title"
                        >
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="col-4"
                        label="Meta Keywords"
                        label-for="video_meta_keywords"
                      >
                        <b-form-input
                          id="video_meta_keywords"
                          v-model="form.meta_keywords"
                          placeholder="Enter Meta Keywords"
                        >
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Meta Description"
                        label-for="video_meta_desc"
                        class="col-6"
                      >
                        <b-form-textarea
                          id="video_meta_desc"
                          v-model="form.meta_description"
                          placeholder="Enter Meta Description"
                          rows="3"
                        ></b-form-textarea>
                      </b-form-group>
                      <b-form-group class="col-6">
                        <label for="video_url_link"
                          >URL Link <span style="color: red">*</span></label
                        >
                        <b-form-input
                          id="video_url_link"
                          v-model="form.url_link"
                          placeholder="Enter URL Link"
                          :class="{
                            'is-invalid': store && $v.form.url_link.$invalid,
                          }"
                        >
                        </b-form-input>
                        <div
                          v-if="store && !$v.form.url_link.required"
                          class="invalid-feedback"
                        >
                          URL Link is required.
                        </div>
                      </b-form-group>
                      <b-form-group class="col-6">
                        <label for="video_time_date"
                          >Schedule a Date
                          <span style="color: red">*</span></label
                        >
                        <b-form-input
                          id="video_time_date"
                          v-model="form.video_timestamp"
                          type="datetime-local"
                          :class="{
                            'is-invalid':
                              store && $v.form.video_timestamp.$invalid,
                          }"
                          :min="disabledDates()"
                        >
                        </b-form-input>
                        <div
                          v-if="store && !$v.form.video_timestamp.required"
                          class="invalid-feedback"
                        >
                          Schedule Date is required.
                        </div>
                      </b-form-group>
                      <b-form-group id="input-group-26" class="col-12">
                        <div style="display: flex; gap: 10px">
                          <b-form-checkbox v-model="form.popup"
                            >Has Popup</b-form-checkbox
                          >
                          <b-form-checkbox v-model="form.visible_on_main_page"
                            >Visible on Home Page</b-form-checkbox
                          >
                          <b-form-checkbox v-model="form.is_open_video"
                            >Is Open Video</b-form-checkbox
                          >
                          <b-form-checkbox v-model="form.video_schedule"
                            >Video Schedule</b-form-checkbox
                          >
                          <b-form-checkbox v-model="form.is_part_of_series"
                            >Is Part Of Series
                          </b-form-checkbox>
                        </div>
                      </b-form-group>
                    </div>
                  </tab-content>
                </form-wizard>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Indonesia">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <b-form-group>
                  <label for="video_title">Video Title</label>
                  <b-form-input
                    id="video_title"
                    v-model="form.translation.indonesia.title"
                    placeholder="Enter Video Title"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group>
                  <label for="video_desc">Tell Us More About The Video</label>
                  <ckeditor
                    v-model="form.translation.indonesia.description"
                    :editor="editor"
                  >
                  </ckeditor>
                </b-form-group>
                <b-form-group>
                  <label for="image">Thumbnail Image</label>
                  <b-form-file
                    id="image"
                    accept="image/*"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'image_name_indonesia')"
                    ref="image_name_indonesia"
                  ></b-form-file>
                  <template
                    v-if="
                      $route.name == 'edit-video' &&
                      edit.image_name_indonesia_url
                    "
                  >
                    <img
                      :src="edit.image_name_indonesia_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="image_name_indonesia_url">
                    <img
                      :src="image_name_indonesia_url"
                      width="128px"
                      height="128px"
                      ref="image_name_indonesia_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group>
                  <label for="meta_title">Meta Title</label>
                  <b-form-input
                    id="meta_title"
                    v-model="form.translation.indonesia.meta_title"
                    placeholder="Enter Meta Title"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group>
                  <label for="meta_keywords">Meta Keywords</label>
                  <b-form-input
                    id="meta_keywords"
                    v-model="form.translation.indonesia.meta_keywords"
                    placeholder="Enter Meta Keywords"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group>
                  <label for="meta_desc">Meta Description</label>
                  <ckeditor
                    v-model="form.translation.indonesia.meta_description"
                    :editor="editor"
                  >
                  </ckeditor>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import MixinRequest from "../../../mixins/request";
import videoMixin from "../../../mixins/ModuleJs/video";
import { FormWizard, TabContent } from "vue-form-wizard";
import Multiselect from "vue-multiselect";
import { required, requiredIf } from "vuelidate/lib/validators";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      editor: ClassicEditor,
      submitted: false,
      store: false,
      form3: false,
      title1: "Add Video",
      title2: "Edit Video",
      items: [
        {
          text: "Back",
          href: "/video",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  mixins: [MixinRequest, videoMixin],
  components: {
    ckeditor: CKEditor.component,
    Layout,
    FormWizard,
    TabContent,
    Multiselect,
  },
  validations: {    
    form: {
      title: { required },
      community_selected: { required },
      partner_id: { required },
      partner_division_id: {
        required: requiredIf((form) => form.card_image_show != ""),
      },
      videocrypt_id: {
        required: requiredIf((form) => form.is_vimeo == 0),
      },
      video_link: {
        required: requiredIf((form) => form.is_vimeo == 1),
      },
      member_tagging: {
        required: requiredIf(
          (form) =>
            form.card_image_show == 1 ||
            form.card_image_show == 2 ||
            form.card_image_show == 4
        ),
      },
      url_link: { required },
      video_timestamp: { required },
    },
  },
  methods: {
    disabledDates() {
      return new Date().toISOString().slice(0, 16);
    },
    validateFormOne() {
      this.submitted = true;
      this.store = true;
      console.log("sadsd------",this.$v.form.is_vimeo);
      
      const isInvalid =
        this.$v.form.title.$invalid ||
        this.$v.form.videocrypt_id.$invalid ||
        this.$v.form.video_link.$invalid;
      if (isInvalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      return true;
    },
    validateFormTwo() {      
      this.store = true;
      const isInvalid =
        this.$v.form.community_selected.$invalid ||
        this.$v.form.member_tagging.$invalid ||
        this.$v.form.partner_id.$invalid ||
        this.$v.form.partner_division_id.$invalid ||
        this.$v.form.url_link.$invalid ||
        this.$v.form.video_timestamp.$invalid ||
        this.$v.form.videocrypt_id.$invalid;
      if (isInvalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      return true;
    },
  },
};
</script>
